<template>
  <div class="calendar">
    <div class="controls">
      <div>
        <h4>
          {{ week[0].match(/(\d+)/g)[0] }} - {{ week[6].match(/(\d+)/g)[0] }}
          {{ months[currentMonth] }}
        </h4>
        <div class="arrows">
          <button @click="prevWeek">&lt;</button>
          <button @click="nextWeek">&gt;</button>
        </div>
      </div>

      <select v-model="currentMonth" @change="changeMonth">
        <option v-for="(month, index) in months" :value="index" :key="month">{{ month }}</option>
      </select>
    </div>

    <div class="week">
      <div
        v-for="day in week"
        :key="day"
        class="day-slot"
        @click="handleDateChange(day)"
        :class="{ selected: currentDay === day }"
      >
        <div>{{ day.split(' ')[0] }}</div>
        <div>{{ day.split(' ')[1] }}</div>
      </div>
      <div class="month">
        <select v-model="currentYear" @change="changeYear">
          <option v-for="year in yearRange" :value="year" :key="year">{{ year }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      currentDay: '',
      currentDate: new Date(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      week: [],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    };
  },
  computed: {
    ...mapState({
      selectedDate: (state) => state.selectedDate,
    }),
    yearRange() {
      const currentYear = new Date().getFullYear();
      const range = [];
      for (let year = 2000; year <= currentYear + 10; year += 1) {
        range.push(year);
      }
      return range;
    },
  },
  methods: {
    ...mapActions(['setSelectedDate']),
    handleDateChange(day) {
      this.currentDay = day;
      this.setSelectedDate(
        new Date(this.currentYear, this.currentMonth, parseInt(day.split(' ')[1], 10)),
      );
    },
    renderCalendar() {
      this.week = [];
      const currentDate = new Date(this.currentDate);

      for (let i = 0; i < 7; i += 1) {
        this.week.push(
          currentDate.toLocaleDateString(undefined, {
            weekday: 'short',
            day: 'numeric',
          }),
        );
        if (this.selectedDate) [this.currentDay] = this.week;
        currentDate.setDate(currentDate.getDate() + 1);
      }
    },
    prevWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7); // Go back 2 weeks
      this.currentMonth = this.currentDate.getMonth();
      this.currentYear = this.currentDate.getFullYear();
      this.renderCalendar();
    },
    nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7); // Go forward 1 week
      this.currentMonth = this.currentDate.getMonth();
      this.currentYear = this.currentDate.getFullYear();
      this.renderCalendar();
    },
    changeMonth() {
      this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
      this.renderCalendar();
    },
    changeYear() {
      this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
      this.renderCalendar();
    },
  },
  mounted() {
    if (this.selectedDate) this.currentDate = this.selectedDate;
    this.renderCalendar();
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.month {
  display: flex;
  align-items: center;
}

select {
  border: solid 1px var(--main-color-500);
  padding: 0.5rem 0.75rem;
  border-radius: 25px;
  font-weight: var(--semi-bold);
  font-size: 0.875rem;
  color: var(--main-color-500);
  option {
    color: var(--font-color-700);
  }
}

.week {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--gray-color-400);
  border-bottom: solid 1px var(--gray-color-400);
  width: 100%;
  padding-left: 1rem;
}

.day-slot {
  padding: 0.625rem 0rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  color: var(--font-color-100);
  &.selected {
    color: var(--main-color-500);
  }
}

button {
  font-size: 1.2rem;
  line-height: 100%;
}

.arrows {
  background-color: #fc6f44;
  color: white;
  border-radius: 25px;
  line-height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 1.25rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    h4 {
      width: 9.5rem;
      text-align: right;
    }
  }
}
</style>
