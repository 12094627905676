<template>
  <div class="view__container">
    <div>
      <div>
        <div>
          <h3>Bienvenido a VALSALARY</h3>
          <p>
            Una plataforma donde podrás valorizar los puestos de tu organización, realizar un
            análisis salarial a tu medida e identificar la equidad de sueldos y género en tu
            empresa.
          </p>
        </div>
        <div>
          <div>
            <unicon name="chart-pie-alt" fill="white" height="28px" width="28px" />
            <h5>Graph</h5>
          </div>
          <div>
            <unicon name="cloud" fill="white" height="28px" width="28px" />
            <h5>Cloud</h5>
          </div>
        </div>
      </div>
      <div>
        <figure><img src="../assets/home3.jpeg" alt="" /></figure>
      </div>
      <div>
        <calendar></calendar>
      </div>
      <div>
        <div>
          <h4>Goals achieved</h4>
          <div>
            <span>
              <unicon name="check" fill="currentColor" height="16px" width="16px" />
              <p>Valorizar los Puestos</p>
            </span>
            <span>
              <unicon name="check" fill="currentColor" height="16px" width="16px" />
              <p>Equidad de Género</p>
            </span>
            <span>
              <unicon name="check" fill="currentColor" height="16px" width="16px" />
              <p>Equidad Salarial</p>
            </span>
            <span>
              <unicon name="check" fill="currentColor" height="16px" width="16px" />
              <p>Estructura Salarial</p>
            </span>
          </div>
        </div>
        <div>
          <figure><img src="../assets/home1.jpeg" alt="" /></figure>
        </div>
      </div>
      <div>
        <div>
          <figure><img src="../assets/home2.jpeg" alt="" /></figure>
        </div>
        <div>
          <h4>VALSALARY progress</h4>
          <div class="bar__container">
            <p>99.9%</p>
            <div class="bar"><div></div></div>
          </div>
          <div class="other">
            <div>
              <p>Goals</p>
              <div class="circle"></div>
            </div>
            <div>
              <p>Active</p>
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h4>PLATAFORMA VALSALARY</h4>
        <p>Comienza a utilizar la plataforma y analiza tu información ahora.</p>
        <span><Button @click="redirect" size="medium" variant="primary">INICIAR</Button></span>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/buttons/Button.vue';
import Calendar from '@/components/Calendar.vue';

export default {
  name: 'StartMenu',
  components: {
    Button,
    Calendar,
  },
  data() {
    return {
      selectedMonth: '',
      selectedYear: '',
    };
  },
  methods: {
    redirect() {
      this.$router.push({ path: 'personas-y-puestos/empleados' });
    },
  },

  computed: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.view__container {
  height: 100%;
  & > div {
    height: 100%;
    gap: 1.5rem;
    display: grid;
    grid-template-areas:
      'main main img'
      'calendar calendar img'
      'goals bar welcome';
    grid-template-rows: calc(34% - 3rem) 33% 33%;
    grid-template-columns: repeat(2, calc(35% - 1rem)) 30%;
    & > div {
      padding: 2rem 2rem;
      &:first-child {
        grid-area: main;
        background-color: var(--gray-color-100);
        display: flex;
        gap: 10rem;
        & > div {
          &:first-child {
            display: flex;
            flex-flow: column;
            gap: 0.5rem;
          }
          &:nth-child(2) {
            display: flex;
            gap: 1rem;
            & > div {
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              gap: 0.875rem;
              padding: 0.875rem;
              * {
                color: white;
              }
              &:first-child {
                background-color: #fe6f43;
              }
              &:nth-child(2) {
                background-color: var(--main-color-500);
              }
            }
          }
        }
      }
      &:nth-child(2) {
        grid-area: img;
        background-color: #fff3db;
      }
      &:nth-child(3) {
        grid-area: calendar;
      }
      &:nth-child(4) {
        grid-area: goals;
        background-color: var(--gray-color-100);
        display: flex;
        padding: 0;
        & > div {
          &:first-child {
            padding: 2rem 2rem;
            display: flex;
            flex-flow: column;
            gap: 1.5rem;
            white-space: nowrap;
            & > div {
              display: flex;
              flex-flow: column;
              span {
                display: flex;
                align-items: center;
                gap: 0.875rem;
              }
            }
          }
          &:nth-child(2) {
            figure,
            img {
              height: 100%;
            }
          }
        }
      }
      &:nth-child(5) {
        grid-area: bar;
        background-color: #202126;
        padding: 0;
        * {
          color: white;
        }
        display: flex;
        & > div {
          &:first-child {
            figure,
            img {
              height: 100%;
            }
          }
          &:nth-child(2) {
            padding: 2rem 2rem;
            &:first-child {
              width: 8rem;
            }
            &:nth-child(2) {
              flex-grow: 1;
              display: flex;
              flex-flow: column;
              justify-content: space-between;
            }
          }
        }
        .bar__container {
          display: flex;
          flex-flow: column;
          gap: 0.25rem;
          .bar {
            height: 0.65rem;
            width: 100%;
            background-color: #3b3c40;
            border-radius: 30px;
            overflow: hidden;
            & > div {
              height: 100%;
              width: 95%;
              background-color: #ecce6b;
            }
          }
        }
        .other {
          display: flex;
          gap: 1.5rem;
          .circle {
            margin: auto;
            background-color: white;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 100%;
          }
        }
      }
      &:nth-child(6) {
        grid-area: welcome;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
    }
  }
}
</style>
